import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Technology } from '../projects/projects.component';

interface ExternalProfiles {
  url: string;
  fa: string;
}

interface Location {
  city: string;
  state: string;
  country: string;
}

interface Language {
  language: string;
  fluency: string;
}

interface AboutData {
  name: string;
  label: string;
  image: string;
  email: string;
  phone: string;
  summary: string;
  location: Location;
  profiles: ExternalProfiles[];
  programming_languages: Technology[];
  technologies: Technology[];
  languages: Language[];
}

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  public aboutData: AboutData;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<AboutData>('../../assets/about.json').toPromise().then(data => {
      this.aboutData = data;
    });
  }

}

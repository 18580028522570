import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface Technology {
  name: string;
  fa: string;
}

interface Project {
  name: string;
  date: string;
  tech: Technology[];
  description: string[];
  links: string[];
}

interface ProjectData {
  projects: Project[];
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  public projectData: ProjectData;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<ProjectData>('../../assets/projects.json').toPromise().then(data => {
      this.projectData = data;
    });
  }

}

<nav class="navbar navbar-default navbar-dark">
  <div class="container-fluid">
    <div class="navbar-header">
      <button type="button" class="navbar-toggle" (click)="collapse=!collapse">
        <span class="icon-bar" *ngFor="let iconBar of [1, 2, 3]"></span>
      </button>
      <a routerLink="/" class="navbar-brand">Mohammad Nadeem</a>
    </div>

    <div class="navbar-collapse" [class.collapse]="collapse" (window:resize)="collapse=true">
    <ul class="nav navbar-nav" routerLinkActive="active">
      <li class="nav-item"><a class="nav-link" routerLink="about">About</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="resume">Resume</a></li>
      <li class="nav-item"><a class="nav-link" routerLink="projects">Projects</a></li>
    </ul>
  </div>
  </div>
</nav>

<router-outlet></router-outlet>
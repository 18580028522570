<div class="content" *ngIf="projectData">
  <div class="text-center">
    <h3>Projects <i class="fa fa-code"></i></h3>
  </div>
  <div class="container text-left" *ngFor="let project of projectData.projects">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>
          {{ project.name }}
          <ng-container *ngFor="let link of project.links">
            <a href="{{ link }}"><i class="fa fa-link fa-1x"></i></a>
          </ng-container >
        </mat-card-title>
        <mat-card-subtitle>
          <mat-chip-list>
            <mat-chip *ngFor="let item of project.tech">
              <i *ngIf="item.fa != ''; else texttech" class="{{ item.fa }}"></i>
              <ng-template #texttech>{{ item.name }}</ng-template>
            </mat-chip>
          </mat-chip-list>
        </mat-card-subtitle>
        <mat-card-subtitle>
          <ng-container *ngFor="let description of project.description;">
            <li class="bullet">{{ description }}</li>
          </ng-container>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>
  
  
<div class="content" *ngIf="aboutData">
  <mat-card>
    <mat-card-title class="text-center">
      {{ aboutData.name }}
    </mat-card-title>
    <mat-card-subtitle class="text-center">
      {{ aboutData.label }}
    </mat-card-subtitle>
    <h4 class="text-wrap text-center">
      {{ aboutData.summary }} 
    </h4>

    <mat-card-content>
      <div class="text-center">
        <img src="{{ aboutData.image }}" class="img-fluid img-thumbnail rounded mx-auto d-block profile-pic"/>
      </div>
      <div class="text-center">
        <strong>Programming Languages:  </strong>
        <ng-container *ngFor="let item of aboutData.programming_languages">
          <i *ngIf="item.fa != ''; else texttech" class="{{ item.fa }}"></i> 
          <ng-template #texttech>{{ item.name }}, </ng-template>
        </ng-container>
      </div>  
      <div class="text-center">
        <strong>Technologies:  </strong>
        <ng-container *ngFor="let item of aboutData.technologies">
          <i *ngIf="item.fa != ''; else texttech" class="{{ item.fa }}"></i> 
          <ng-template #texttech>{{ item.name }}, </ng-template>
        </ng-container>
      </div>  
      <div class="text-center">
        <strong>Languages:  </strong>
        <ng-container *ngFor="let item of aboutData.languages">
          {{ item.language }} ({{ item.fluency }}), 
        </ng-container>
      </div>
    </mat-card-content>
    <mat-card-footer>
      <div class="text-center">
        <a href="mailto:{{ aboutData.email }}">
          <i class="fa fa-envelope-square fa-1x"></i> 
          {{ aboutData.email }}
        </a>
      </div>
      <div class="text-center">
        <a href="tel:{{ aboutData.phone }}" class="col-lg">
          <i class="fa fa-phone fa-1x"></i> 
          {{ aboutData.phone }} 
        </a>
      </div>
      <div class="text-center">
        <ng-container *ngFor="let profile of aboutData.profiles">
          <a href="{{ profile.url }}">
            <i class="fa {{ profile.fa }} fa-2x "></i>
          </a>
        </ng-container>
      </div>
    </mat-card-footer>
  </mat-card>
</div>
<div class="content" *ngIf="resumeData">
  
  <div class="text-center">
    <h3>Work Experience <i class="fa fa-briefcase"></i></h3>
  </div>  
  <div class="container text-left" *ngFor="let job of resumeData.work">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>
          <div> 
            {{ job.position }} @ <a href="{{ job.website }}"> {{ job.company }} </a>
          </div>
          <h5> 
            {{ job.startDate }} to {{ job.endDate }}
          </h5>
        </mat-card-title>
        <mat-card-subtitle> {{ job.summary }} </mat-card-subtitle>
        <mat-card-subtitle *ngFor="let highlight of job.highlights">
          <li class="bullet">{{ highlight }}</li>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>  

  <br/>

  <div class="text-center">
    <h3>Education <i class="fa fa-graduation-cap"></i></h3>
  </div>
  <div class="container text-left" *ngFor="let edu of resumeData.education">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>
          <div> 
            {{ edu.studyType }}
            <h5>{{ edu.area }}</h5>
          </div>
        </mat-card-title>
        <mat-card-subtitle> 
          {{ edu.institution }}
          <h6> 
            {{ edu.startDate }} - {{ edu.endDate }}
          </h6>
        </mat-card-subtitle>
        <mat-card-subtitle class="text-wrap">
          <h3> Areas of Focus: </h3>
          <mat-chip-list>
            <mat-chip *ngFor="let area of edu.areas_of_focus">
              {{ area }}
            </mat-chip>
          </mat-chip-list>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <br/>

  <div class="text-center">
    <h3>Awards <i class="fa fa-trophy"></i></h3>
  </div>

  <div class="container text-left" *ngFor="let award of resumeData.awards">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title> 
          {{ award.title }}, {{ award.date }}  
          <ng-container *ngFor="let url of award.urls">
            <a href="{{ url }}"><i class="fa fa-link"></i></a>
          </ng-container>
        </mat-card-title>
        <mat-card-subtitle> 
          {{ award.summary }} 
          <h6> {{ award.awarder }} </h6>
        </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <br/>

  <div class="text-center">
    <h3>Publications <i class="fa fa-leanpub"></i></h3>
  </div>
  <div class="container text-left" *ngFor="let publication of resumeData.publications">
    <mat-card class="card">
      <mat-card-header>
        <mat-card-title>
          {{ publication.authors }}, {{ publication.name }}, {{ publication.publisher }}, {{ publication.releaseDate }} 
          <a href="{{ publication.url }}"><i class="fa fa-link"></i></a>
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface Job {
  company: string;
  position: string;
  website: string;
  startDate: string;
  endDate: string;
  summary: string;
  highlights: string[];
}

interface Education {
  institution: string;
  area: string;
  studyType: string;
  startDate: string;
  endDate: string;
  gpa: string;
  areas_of_focus: string[];
}

interface Award {
  title: string;
  date: string;
  awarder: string;
  urls: string[];
  summary: string;
}

interface Publication {
  name: string;
  authors: string;
  publisher: string;
  releaseDate: string;
  url: string;
}

interface ResumeData {
  work: Job[];
  education: Education[];
  awards: Award[];
  publications: Publication[];
}

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.css']
})
export class ResumeComponent implements OnInit {
  public resumeData: ResumeData;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.http.get<ResumeData>('../../assets/resume.json').toPromise().then(data => {
      this.resumeData = data;
    });
  }

}
